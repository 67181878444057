import { memo } from "react"
import { Link } from "react-router-dom"

function OfficeBearbanner(){
    return (<>
        <div className="officebanner banner">
            <div className="innerboxbanner">
                <Link to="/" className="linkbanner"><h4>Home </h4></Link>
                <span className="bannerslce"><h3> / </h3></span>
                <span className="bannernamme"><h4>Office Bearers</h4></span>
            </div>
        </div>
    </>)
}
export default memo(OfficeBearbanner)