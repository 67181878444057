import { memo, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function Mobilemenu(props) {
  const [sty, setSty] = useState("none");
  const changer = {
    display: `${props.menu}`,
  };
  const change = {
    display: `${sty}`,
    paddingLeft: "26px",
  };
  return (
    <>
      <div style={changer} className="mobmenusec">
        <div className="topheaderbox logotext2"></div>
        <div className="container position">
          <div className="topheaderbox firsttext"></div>
          <div className="headerTop">
            <div className="subheder">
              <div className="logobox">
                <Link to="/" className="img">
                  <img src="../photo/logo.png" alt="" />
                </Link>
              </div>
              <div className="headersectw logotext">
                <div className="Headertext firsttext">
                  <img src="../photo/logo-text.png" alt="" />
                </div>
                <div className="Headertext logotext2">
                  <img src="../photo/logotext2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="secmobcon">
          <div className="container p-2">
            <div className="fothin">
              <Link to="/" className="fothinp textseconone">
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>Home</h6>
                </div>
              </Link>
            </div>
            <div className="fothin">
              <Link to="/about" className="fothinp textseconone">
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>About Us</h6>
                </div>
              </Link>
            </div>
            <div className="fothin">
              <Link to="/activity" className="fothinp textseconone">
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>Activity</h6>
                </div>
              </Link>
            </div>
            <div className="fothin">
              <Link
                onClick={() => {
                  setSty("block");
                }}
                className="fothinp textseconone"
              >
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>Events</h6>
                </div>
              </Link>
            </div>
            <div style={change} className="pl-2">
              <div className="fothin">
                <Link to="/event/twotwo" className="fothinp textseconone">
                  <LazyLoad className="fotthph textseconone">
                    <img src="../photo/right-arrow.png" alt="" />
                  </LazyLoad>
                  <div className="forthphn fontofmobli">
                    <h6 style={{ color: "black" }}>World Stroke Day 2022</h6>
                  </div>
                </Link>
              </div>
              <div className="fothin">
                <Link to="/event/twoone" className="fothinp textseconone">
                  <LazyLoad className="fotthph textseconone">
                    <img src="../photo/right-arrow.png" alt="" />
                  </LazyLoad>
                  <div className="forthphn fontofmobli">
                    <h6 style={{ color: "black" }}>World Stroke Day 2021</h6>
                  </div>
                </Link>
              </div>
              <div className="fothin">
                <Link to="/event/onenine" className="fothinp textseconone">
                  <LazyLoad className="fotthph textseconone">
                    <img src="../photo/right-arrow.png" alt="" />
                  </LazyLoad>
                  <div className="forthphn fontofmobli">
                    <h6 style={{ color: "black" }}>World Stroke Day 2019</h6>
                  </div>
                </Link>
              </div>
            </div>
            <div className="fothin">
              <Link to="/office/present" className="fothinp textseconone">
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>Office Bearers</h6>
                </div>
              </Link>
            </div>
            <div className="fothin">
              <Link to="/contact" className="fothinp textseconone">
                <LazyLoad className="fotthph textseconone">
                  <img src="../photo/right-arrow.png" alt="" />
                </LazyLoad>
                <div className="forthphn fontofmobli">
                  <h6 style={{ color: "black" }}>Contact Us</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            props.setMenu("none");
            setSty("none");
          }}
          className="fothin backbutton"
        >
          <div className="container">
            <div className="fothinp textseconone">
              <div className="forthphn fontofmobli">
                <h6 style={{ color: "black" }}>Back</h6>
              </div>
            </div>
          </div>
        </button>
      </div>
    </>
  );
}
export default memo(Mobilemenu);
