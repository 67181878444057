import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Hospitalinfo(){
    return (<>
    <section className="mt-5 pt-2 mb-0 bannerone">
    <div className=""></div>
        <div className="container">
            <div className="row hosincol">
                <div className="col-lg-6 coneventa">
                    <h2>ಬೆಂಗಳೂರು ಪಾರ್ಶ್ವವಾಯು ಸೇವಾ ಸಂಘ</h2>
                    <h2>Welcome to Bangalore Stroke Support Group</h2>
                    <h5>Bangalore Stroke Support Group (BSSG) is an initiative by association of service oriented doctors and stroke victims. This online platform is built to bring comprehensive awareness and preventive care about deadly stroke attacks among the public.It is a support group consisting of neurologists , physical therapists , occupational therapists , speech therapists & stroke survivors . This group meets once every three months where the patients & their caregivers can discuss their problems with a multidisciplinary group consisting of the above team .This team will help in finding the solutions & improving the quality of lives of the patients & their caregivers </h5>
                    <h4>BSSG Objective :</h4>
                    <h5>This group conducts some events and conferences to support & guide the stroke survivors  to live happily . They give the ideas , guide them what to do in regular life , how to take care stroke , guide them for any testing to be done  by reference hospitals . All patients are free * & no need to pay anything for the Conference or events meeting . </h5>
                    <h5 style={{marginBottom:"0px"}}>BSSG conducts periodic CME'S & scientific meetings for dissemination of recent scientific materials & latest advancements in the field of stroke & rehabilitation . This group also conducts & engages in annual events on world stroke day "October 29" for creating public awareness on the same .</h5>
                </div>
                <div className="col-lg-6 alicenter">
                <div className="pt-5 row">
                            <div className="officebearphotoa">
                                <div className="backhedera"></div>
                                <LazyLoad className="loadhi">
                                <img src="photo/bigphoto.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.Supriya Dasgupta</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Founder</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Hospitalinfo)