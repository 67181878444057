import { memo, useState } from "react"
import Mobilemenu from "./Mobilemenu"
import FixHeader from "./FixHeader"
import Header from "./Header"

function Menu(){
    const [menu, setMenu]=useState("none")
    const [menuset, setMenuset]=useState("0%")
    return (<>
        <Mobilemenu menu={menu} setMenu={setMenu} menuset={menuset} setMenuset={setMenuset}/>
        <FixHeader setMenu={setMenu} setMenuset={setMenuset}/>
        <Header setMenu={setMenu} setMenuset={setMenuset}/>
    </>)
}
export default memo(Menu)