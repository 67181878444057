import { useEffect, useState } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./Inner.css"
import LazyLoad from "react-lazy-load";
function PastOffice(){
    const [index,setIndex]=useState(0);
    const [bodyoneone, setBodyoneone]=useState(bodyone);
    const [bodytwotwo, setBodytwotwo]=useState(bodytwo);
    const [righttrue, setRighttrue]=useState(true);
    useEffect(()=>{
            let settimer=setInterval(()=>{
                if(window.innerWidth<992){
                if(righttrue){
                    if(index<1){
                        setIndex(index+1);
                    }else{
                        setIndex(0);
                    }
                }else{
                    clearInterval(settimer);
                }
            }
            },3000)
            return ()=>{
                clearInterval(settimer);
            }
    })
    const transfer={
        transform:`translateX(-${index*33.3333}%)`
       }
    function rightindex(){
        setRighttrue(false)
        if(index<1){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
    }
    function leftindex(){
        setRighttrue(false)
        if(index>-1){
            setIndex(index-1);
        }else{
            setIndex(1);
        }
    }
    function sliderstop(){
        setRighttrue(false)
    }
    return (
        <>
        <section onClick={sliderstop} className="section">
            <div className="container">
                <div className="row cetifier">
                    <h1>PAST OFFICE BEARERS</h1>
                </div>
            </div>
            <div className="container cetifierbox">
                <div className="headeroffbox col-lg-8">
                <div className="hiderofficeover">
                <div className="headerofficepast" style={transfer}>
                    {bodyoneone}
                    {bodytwotwo}
                    {bodyoneone}
                </div>
                </div>
                <button onClick={rightindex} className="rightbutton checkright"><img src="photo/right-arrow (2).png" alt="" /></button>
                <button onClick={leftindex} className="leftbutton checkright"><img src="photo/left-arrow.png" alt="" /></button>
            </div>
            </div>
        </section>
        </>
    )
}
export default PastOffice;

const bodyone=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="../photo/subhas.jpg" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr. G.T Subhas</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Past President</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodytwo=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="../photo/vikram.jpg" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr. Vikram Huded</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Founder Secretary</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}