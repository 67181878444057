import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { memo } from "react"
function HomeMap(){
    return (<>
    <section className="sechomap">
        <div className="container">
            <div className="rowa">
                <div className="addinfob">
                    <h5>Address</h5>
                    <h6>Bangalore Parshvavayu Seva Sangha F-003, Nagarjuna Green Ridge Apartment, 19th Main Road, 27th Cross, 80 Feet Road 2nd Phase H.S.R Layout, Bangalore-560102</h6>

                    <h5>Phone</h5>
                    <h6>  Mr. Muralidharan K.V - +91 9901066260</h6>
                    
                    <h5>Email</h5>
                    <h6>muralidharankv@gmail.com</h6>

                    <h5 className="mb-0">Follow</h5>
                    <div className="followali">
                        <ul className="iconbox">
                            <li><a href=""><img src="photo/facebook (3).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/instagram (2).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/twitter (3).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/google.png" alt="" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
            <iframe className="mapofhome" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25364.303017160033!2d77.63155831001062!3d12.901940303988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1498f4bb791d%3A0x4595d0cd33c4cbca!2sNagarjuna%20Green%20Ridge%20Apartment%2C%20ITI%20Layout%2C%20Sector%202%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102!5e0!3m2!1skn!2sin!4v1684756720753!5m2!1skn!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
    </>)
}
export default memo(HomeMap)