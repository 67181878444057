import { memo } from "react"
import ContactSlider from "./ContactSlider"
import ContactForm from "./ContactForm"
import Contactmap from "./Contactmap"

function Contact(){
    return (<>
    <ContactSlider/>
    <ContactForm/>
    <Contactmap/>
    </>)
}
export default memo(Contact)