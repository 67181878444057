import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Link } from "react-router-dom";
function Header(props){
    return <>
            <div className="topheaderbox logotext2">
        </div>
    <div className="container position zheder">
        <div className="topheaderbox firsttext">
        </div>
        <div className="headerTop">
            <div className="subheder">
            <div className="logobox">
                <Link className="img"><img src="../photo/logo.png" alt="" /></Link>
            </div>
            <div className="headersectw logotext">
                <div className="Headertext firsttext">
                    <img src="../photo/logo-text.png" alt="" />
                </div>
                <div className="Headertext logotext2">
                    <img src="../photo/logotext2.png" alt="" />
                </div>
                <div className="headermenu">
                    <ul className="Menubar">
                        <li><Link to="/" className="menutext">Home</Link></li>
                        <li><Link to="/about" className="menutext">About Us</Link></li>
                        <li><Link to="/event/twotwo" className="menutext">Events</Link></li>
                        <li><Link to="/activity" className="menutext">Activity</Link></li>
                        <li><Link to="/office/present" className="menutext">Office Bearers</Link></li>
                        <li><Link to="/contact" className="menutext">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
            </div>
            <div className="headerthreetw">
                <div className="iconboxtop">
                    <Link to="/contact" className="involve">Involve Now</Link>
                        <ul className="iconbox">
                            <li><a href="https://www.facebook.com/people/Bangalore-Stroke-Support-Group-%E0%B2%AC%E0%B3%86%E0%B2%82%E0%B2%97%E0%B2%B3%E0%B3%82%E0%B2%B0%E0%B3%81-%E0%B2%AA%E0%B2%BE%E0%B2%B0%E0%B3%8D%E0%B2%B6%E0%B3%8D%E0%B2%B5%E0%B2%B5%E0%B2%BE%E0%B2%AF%E0%B3%81-%E0%B2%B8%E0%B3%86%E0%B3%95%E0%B2%B5%E0%B2%BE-%E0%B2%B8%E0%B2%82%E0%B2%98/100072135099512/"><img src="../photo/facebook (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/instagram (2).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/twitter (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/google.png" alt="" /></a></li>
                        </ul>
                    <button onClick={()=>{
                        props.setMenu("block")
                        props.setMenuset(50)
                    }} className="toglebutton"><img src="photo/menu (1).png" alt="" /></button>
                </div>
            </div>
        </div>
    </div>
    </>
}
export default Header;