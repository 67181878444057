import { memo, useEffect, useState } from "react"
import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import LazyLoad from "react-lazy-load";

function HomeInfoSlider(){
    const [numer, setNumber]=useState(0);
    const [numtrue, setNumtrue]=useState(true);
    const menu=[
        {
            img:"photo/headone.jpg",
            name:"Stroke Braun Attack",
            cont:"Is the first leading cause of disability and third leading cause of death in India."
        },
        {
            img:"photo/headcake.jpg",
            name:"What are the types of strokes?",
            cont:"Ischemic stroke – where there is block in a vessel which supplies the brain. (70-80% of strokes are ischemic)"
        },
        {
            img:"photo/headfour.jpg",
            name:"What are the types of strokes?",
            cont:"Hemorrhagic stroke (Brain hemorrhage) – bleeding in the brain due to rupture of blood vessel in the brain."
        },
        {
            img:"photo/headthree.jpg",
            name:"What are the comman symptoms?",
            cont:"Weakness in limbs, difficulty in speaking, loss of vision, Imbalance, decreased sensation, double vision, severe headache and vomiting, loss of consciousness, epileptic fit etc."
        },
    ]
    useEffect(()=>{
        const timer=setInterval(()=>{
            if(numtrue==true){
                if(numer<3){
                    setNumber(numer+1)
                }else{
                    setNumber(0)
                }
            }else{
                clearInterval(timer)
            }
        },2000)
        
        return (()=>{
            clearInterval(timer)
        })
    })
    function first(){
        setNumber(0);
        setNumtrue(false)
    }

    const changer={
        transform:`translateX(-${numer*25}%)`
    }
    return (<>
    <section className="homeinsl">
        <div className="container">
            <div className="overnuminfo">
            <div style={changer} className="disnuminfo">
            {
                menu.map((elem, num)=>{
                    const {img, name, cont}=elem;
                    {
                        return (<>
                        <div className="rowwid">
            <div className="col-md-4 painfo">
                <LazyLoad className="homeinfoim">
                    <img src={img} alt="" />
                </LazyLoad>
            </div>
            <div className="col-md-8 conouter">
                <div className="contenhomesl">
                <h2>{name}</h2>
                <h3>{cont}</h3>
                </div>
            </div>
            </div>
                        </>)
                    }
                })
            }
            </div>
            </div>
            <div className="dotouter">
                <ul className="dotinner">
                    <LazyLoad className="dothoimg">
                        <img onClick={first} src="photo/record (1).png" alt="" />
                    </LazyLoad>
                    <LazyLoad className="dothoimg">
                        <img onClick={()=>{
                            setNumtrue(false)
                            setNumber(1)
                        }}  src="photo/record (1).png" alt="" />
                    </LazyLoad>
                    <LazyLoad className="dothoimg">
                        <img onClick={()=>{
                            setNumtrue(false)
                            setNumber(2)
                        }}  src="photo/record (1).png" alt="" />
                    </LazyLoad>
                    <LazyLoad className="dothoimg">
                        <img onClick={()=>{
                            setNumtrue(false)
                            setNumber(3)
                        }}  src="photo/record (1).png" alt="" />
                    </LazyLoad>
                </ul>
            </div>
        </div>
    </section>
    </>)
}
export default memo(HomeInfoSlider);