import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Fouractivity(){
    return(<>
        <div className="sectionbott mb-0 pb-5 pt-2">
       <div className="container">
           <div className="row cetifier evenpm-2">
               <h3>The program on 29th May</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>The program on 29th May is as follows… 10am to 11 am ——Brain therapy by Dr Gopu, Neuropsychologist and hypnotherapist, BNC. 11 to 12 pm Art Therapy by Mrs Supriya das gupta, President elect, SSG bangalore. 12 – 1pm- question and answer session.</h5>
               </div>
           </div>
           <div className="row">
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaea.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaeb.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaec.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaed.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaee.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaef.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaeg.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaeh.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaei.jpg" alt="" />
            </LazyLoad>
           </div>
       </div>
   </div>
    </>)
}
export default memo(Fouractivity)