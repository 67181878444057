import { memo } from "react"
import { Link } from "react-router-dom"

function Eventbanner(){
    return (<>
        <div className="eventbanner banner">
            <div className="innerboxbanner">
                <Link to="/" className="linkbanner"><h4>Home </h4></Link>
                <span className="bannerslce"><h3> / </h3></span>
                <span className="bannernamme"><h4> Event</h4></span>
            </div>
        </div>
    </>)
}
export default memo(Eventbanner)