import { memo, useState } from "react"
import Onenineinfo from "./Onenineinfo"
import LazyLoad from "react-lazy-load"

function Onenine(){
    const [number, setNumber]=useState(60)
    const [num3, setNum3]=useState(0)
    let setDis="flex"
    const menu=[{
                    src:"https://www.youtube.com/embed/XYn7k4wmOH4",
                    info:"Gangavathi Pranesh Comedy In World Stroke Support Day"
                },
                {
                    src:"https://www.youtube.com/embed/DpDnfO2Ukc8",
                    info:"World Stroke Support Day 2019"
                },
                {
                    src:"https://www.youtube.com/embed/EI427GY4cJA",
                    info:"World Stroke Support Day 2019 By Bangalore Stroke Support"
                },
    ]
    let changer={
        display:`${setDis}`
    }
    function callme(){
        setDis="none"
        changer={
            display:`${setDis}`
        }
    }
    return (<>
                    
        <div className="sectionbott mb-0 pb-4 pt-5">
       <div className="container">
       <div className="vedioscrolla mb-5">
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2019 VIDEO</h3>
           </div>
                    <div className="row ">
                            {
                                menu.map((elem)=>{
                                    const {src,info}=elem;
                                    return (<>
                                    <div className="col-md-6 col-12 pade pt-3">
                                        <div className="vediooter">
                                            {/* <LazyLoad className="vedioinner"> */}
                                            <div className="imgve"></div>
                                            <iframe className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            {/* </LazyLoad> */}
                                        </div>
                                        <h5 className="mt-3">{info}</h5>
                                    </div>
                                    </>)
                                })
                            }
                        </div>
                    </div>
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2019</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>Event conducted on the occasion of world stroke day on Oct 20 2019 at BMC Alumni auditorium. Conduct campaigns to give information about the signs and symptoms of stroke, the importance of seeking immediate medical attention, and the resources available to stroke survivors.</h5>
               </div>
           </div>
           <div className="row">
            {
               Onenineinfo.map((elem,num)=>{
                    const {img}=elem
                    if(Onenineinfo.length>num && number>num){
                        if(Onenineinfo.length-1==num){
                            callme()
                        }
                    {
                        return (<>
                        <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                            <img src={img} alt="" />
                        </LazyLoad>
                        </>)
                    }
                    }
                })
            }
           </div>
           <div style={changer} onClick={()=>{
            setNumber(number+60)
           }} className="loadmore pt-4">
            <button className="loadbut"><h6>Load More</h6></button>
           </div>
       </div>
   </div>
    </>)

}
export default memo(Onenine)