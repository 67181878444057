import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Link } from "react-router-dom"
function Welcomebox(){
    return (<>
    <div className="section welbanner">
        <div className="container">
            <div className="row textboxwel">
                <div className="col-lg-8 welboxone">
                    <h3>Welcome to</h3>
                    <h1>Bangalore Stroke Support Group</h1>
                </div>
                <Link to="/contact" className="col-lg-4 welboxtwo textdeconone">
                    <div className="innerwelbox"><h6>Get Involved Now</h6></div>
                </Link>
            </div>
        </div>
    </div>
    </>)
}
export default Welcomebox;