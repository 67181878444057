import { memo } from "react"
import OfficeBearsbanner from "./OfficeBearsbanner"
import Officeselect from "./Officeselect"
import {BrowserRouter, Router, Routes,Route, Outlet,} from "react-router-dom"
import PastOffice from "../PastOffice"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

function OfficeBearpage(){
    return (<>
    <OfficeBearsbanner/>
    <Officeselect/>
    <div style={{marginTop:"-50px"}}>
    <Outlet/>
    </div>
    </>)
}
export default memo(OfficeBearpage)