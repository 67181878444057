import { useEffect, useRef, useState } from "react";
import "./Inner.css"
import LazyLoad from "react-lazy-load";

function OfficeMember(){
    const [index,setIndex]=useState(0);
    const [bodyoneone, setBodyoneone]=useState(bodyone);
    const [bodytwotwo, setBodytwotwo]=useState(bodytwo);
    const [bodythreethree, setBodythreethree]=useState(bodythree);
    const [bodyfourfour, setBodyfourfour]=useState(bodyfour);
    const [bodyfivefive, setBodyfivefive]=useState(bodyfive);
    const [bodysixsix, setBodysixsix]=useState(bodysix);
    const [righttrue, setRighttrue]=useState(true);


    useEffect(()=>{
        let settimer=setInterval(()=>{
            if(righttrue){
                if(index<5){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else{
                clearInterval(settimer)
            }
        },3000)
        return ()=>{
            clearInterval(settimer);
        }
    })
    const transfer={
        transform:`translateX(-${index*12.5}%)`
       }
    function rightindex(){
        setRighttrue(false)
        if(index<5){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
    }
    function leftindex(){
        setRighttrue(false)
        if(index>-1){
            setIndex(index-1);
        }else{
            setIndex(5);
        }
    }
    function sliderstop(){
        setRighttrue(false)
    }
    return (<>
        <section onClick={sliderstop} className="sectionbott">
            <div className="container">
                <div className="row cetifier">
                    <h1>CURRENT E.C.MEMBERS</h1>
                </div>
            </div>
            <div className="container">
                <div className="headeroffbox">
                <div className="hiderofficeover">
                <div className="headerofficemem" style={transfer}>
                    {bodyoneone}
                    {bodytwotwo}
                    {bodythreethree}
                    {bodyfourfour}
                    {bodyfivefive}
                    {bodysixsix}
                    {bodyoneone}
                    {bodytwotwo}
                    
                </div>
                </div>
                <button onClick={rightindex} className="rightbutton"><img src="photo/right-arrow (2).png" alt="" /></button>
                <button onClick={leftindex} className="leftbutton"><img src="photo/left-arrow.png" alt="" /></button>
            </div>
            </div>
        </section>
        </>)
}
export default OfficeMember;

const bodyone=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.K.Gopakumar</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodytwo=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.Uday Murgod</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodythree=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Mr.K.S.Shreyus</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodyfour=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.Sreedevi</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodyfive=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.P.R.Srijitesh</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodysix=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr.Surya Narayan Sharma</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>E.C.member</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}