import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";
function FixHeader(props){
    const [control, setControl]=useState("none")
    useEffect(()=>{
        window.addEventListener('scroll',()=>{
            if(window.scrollY>200){
                setControl("block")
            }else{
                setControl("none")
            }
            return (()=>{
                window.removeEventListener('scroll',()=>{

                })
            })
        })
    })
    const changer={
        display:`${control}`
    }
    return <>
            <div style={changer} className="botomfix">
    <div className="container position">
        <div style={{display:"flex"}} className="headerTop justifix">
            <div className="subheder">
            <div className="logobox fixwidis">
                <Link className="fiximg"><img src="../photo/logo.png" alt="" /></Link>
            </div>
            <div style={{paddingLeft:"0px"}} className="headersectw logotext ml-0 pl-0 pt-0">
                <div className="fixheadermenu">
                    <ul className="Menubar mb-0">
                        <li><Link to="/" className="menutext fixtest">Home</Link></li>
                        <li><Link to="/about" className="menutext fixtest">About Us</Link></li>
                        <li><Link to="/event/twotwo" className="menutext fixtest">Events</Link></li>
                        <li><Link to="/activity" className="menutext fixtest">Activity</Link></li>
                        <li><Link to="/office/present" className="menutext fixtest">Office Bearers</Link></li>
                        <li><Link to="/contact" className="menutext fixtest">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
            </div>
            <div style={{display:"flex"}} className="headerthreetwaa alicenter">
                <div>
                    <button onClick={()=>{
                        props.setMenu("block")
                        props.setMenuset(50)
                    }} className="toglebutton"><img src="photo/menu (1).png" alt="" /></button>
                </div>
                <div className="iconboxtop dispacherfix">
                    <Link to="/contact" className="involve">Involve Now</Link>
                        <ul className="iconboxa">
                            <li><a href="https://www.facebook.com/people/Bangalore-Stroke-Support-Group-%E0%B2%AC%E0%B3%86%E0%B2%82%E0%B2%97%E0%B2%B3%E0%B3%82%E0%B2%B0%E0%B3%81-%E0%B2%AA%E0%B2%BE%E0%B2%B0%E0%B3%8D%E0%B2%B6%E0%B3%8D%E0%B2%B5%E0%B2%B5%E0%B2%BE%E0%B2%AF%E0%B3%81-%E0%B2%B8%E0%B3%86%E0%B3%95%E0%B2%B5%E0%B2%BE-%E0%B2%B8%E0%B2%82%E0%B2%98/100072135099512/"><img src="../photo/facebook (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/instagram (2).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/twitter (3).png" alt="" /></a></li>
                            <li><a href=""><img src="../photo/google.png" alt="" /></a></li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
            </div>
    </>
}
export default memo(FixHeader);