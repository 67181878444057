import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Threeactivity(){
    return (<>
        <div className="sectionbott mb-0 pb-4 pt-2">
       <div className="container">
           <div className="row cetifier evenpm-2">
               <h3>The program on 17th April</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>The program on 17th April is as follows… 10am to 10.45 am ——Psychological aspects of stroke by Dr Prathiba Sharan, Director, Neuro rehabilitation, NewRo 10.45 am to 11.30 am — Care givers role in stroke by Dr Gopu, Neuropsychologist and hypnotherapist, BNC. 11.30 to 12.15 pm Speach and Swallow therapy by Dr Smitha Caren Mathias, Senior Speech swallow specialist, NH mazumdar Medical center.</h5>
               </div>
           </div>
           <div className="row">
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaca.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gacb.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gacc.jpg" alt="" />
            </LazyLoad>
           </div>
       </div>
   </div>
    </>)
}
export default memo(Threeactivity)