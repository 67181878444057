import { memo, useState } from "react";
import twoetwoevent from "./TwoTwoinfo";
import LazyLoad from "react-lazy-load";
import { Twothreeinfo } from "./Twothreeinfo";

function TwoThree() {
  const [number, setNumber] = useState(60);
  const [num3, setNum3] = useState(0);
  let setDis = "flex";
  const menu = [
    {
      src: "https://www.youtube.com/embed/4bSWVlrPnVE",
      info: "Short Video Of Bangalore Stroke Nursing Conclave 2022 Final",
    },
    {
      src: "https://www.youtube.com/embed/eCuJ3jo6t0w",
      info: "Bangalore Stroke Nursing Conclave 2022 Final Video",
    },
  ];
  let changer = {
    display: `${setDis}`,
  };
  function callme() {
    setDis = "none";
    changer = {
      display: `${setDis}`,
    };
  }
  return (
    <>
      <div className="sectionbott mb-0 pb-4 pt-5">
        <div className="container">
          {/* <div className="vedioscrolla mb-5">
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2022 VIDEO</h3>
           </div>
                    <div className="row ">
                            {
                                menu.map((elem)=>{
                                    const {src,info}=elem;
                                    return (<>
                                    <div className="col-md-6 col-12 pade pt-3">
                                        <div className="vediooter">
                                            <div className="imgve"></div>
                                            <iframe className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                           
                                        </div>
                                        <h5 className="mt-3">{info}</h5>
                                    </div>
                                    </>)
                                })
                            }
                        </div>
                    </div> */}
          <div className="row cetifier evenpm-2">
            <h3>WORLD STROKE DAY 2023</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 conevent">
              <h5>
                Organized Bangalore Stroke Support Group World Stroke Day Event
                2023 as Hon.Secretary of Bangalore Stroke Support Group. It was
                for benefit of stroke survivors and their caregivers and to gen
                public to create awareness on stroke. Program was presided by Dr
                BN, Gangadhar, Chairman NMC and Former Director of NIMHANS,
                popular TV9 Senior Anchor Mr Ranganath Bharadwaj, Mrs Indumathi
                Rao, Dr GT Subhas, Dr Vikram Huded, Mr Mohanram NK and all
                office bearers of BSSG and Karnataka Stroke Foundation. Was
                attended by more than 250 attendees who carried home the message
                of TIME is BRAIN. Awareness talks by experts was followed by
                street play on stroke. Program ended with musical performance by
                AVNEE group. Karnataka Stroke Foundation, led by Dr GT Subhas
                and Dr Suryanarayana Sharma organized Lalbagh exhibition which
                was inaugurated by Dr Sujatha Rathod and presided by Dr
                Gangadhar sir. Charts on Stroke prevention and treatment were
                displayed and there was good public interaction. Together
                #begreaterthanstroke is the theme of this years WSD.
              </h5>
            </div>
          </div>
          <div className="row">
            {Twothreeinfo.map((elem, num) => {
              const { img } = elem;
              if (twoetwoevent.length > num && number > num) {
                if (twoetwoevent.length - 1 == num) {
                  callme();
                }
                {
                  return (
                    <>
                      <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                        <img src={img} alt="" />
                      </LazyLoad>
                    </>
                  );
                }
              }
            })}
          </div>
          <div
            style={changer}
            onClick={() => {
              setNumber(number + 60);
            }}
            className="loadmore pt-4"
          >
            <button className="loadbut">
              <h6>Load More</h6>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(TwoThree);
