import { memo } from "react"
import Eventbanner from "./Eventbanner"
import Eventselect from "./Eventselect"
import Twotwo from "./Twotwo"
import Twoone from "./Twoone"
import Onenine from "./Onenine"
import { Outlet } from "react-router-dom"

function Event(){
    return (<>
    <Eventbanner/>
    <Eventselect/>
    
    <Outlet/>
    </>)
}
export default memo(Event)