import { memo } from "react"
import Activitybanner from "./Activitybanner"
import Firstactivity from "./Firstactivity"
import Secondactivity from "./Secondactivity"
import Threeactivity from "./Threeactivity"
import Fouractivity from "./Fouractivity"

function Activity(){
    return(<>
    <Activitybanner/>
    <Firstactivity/>
    <Secondactivity/>
    <Threeactivity/>
    <Fouractivity/>
    </>)
}
export default memo(Activity)