import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { useEffect, useRef, useState } from "react"
import LazyLoad from "react-lazy-load";
import { memo } from "react";
import { Link } from "react-router-dom";
import HomeInfo22v from "./HomeInfo22v";
import twoetwoevent from "./Event/TwoTwoinfo";
function HomeGallery22(){
    const [number, setNumber]=useState()
    const [index,setIndex]=useState(2)
    const [cycle, setCycle]=useState(true)
    const myref=useRef();
function right(){
    setCycle(false)
    if(window.innerWidth>1200){
        setNumber(25)
        if(index<twoetwoevent.length-1){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else if(window.innerWidth>992){
        setNumber(33.3333)
        if(index<twoetwoevent.length){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else if(window.innerWidth>768){
        setNumber(50)
        if(index<twoetwoevent.length-1){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }else{
        setNumber(100)
        if(index<twoetwoevent.length-2){
            setIndex(index+1);
        }else{
            setIndex(0)
        }
    }
}

function left(){
    setCycle(false)
    if(window.innerWidth>1200){
        setNumber(25)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(twoetwoevent.length-1)
        }
    }else if(window.innerWidth>992){
        setNumber(33.3333)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(twoetwoevent.length)
        }
    }else if(window.innerWidth>768){
        setNumber(50)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(twoetwoevent.length+1)
        }
    }else{
        setNumber(100)
        if(index>0){
            setIndex(index-1);
        }else{
            setIndex(twoetwoevent.length+2)
        }
    }
}

function stop(){
    setCycle(false)
}

const [men,setmen]=useState(twoetwoevent)
useEffect(()=>{
    const timer=setInterval(()=>{
        if(cycle==true){
            if(window.innerWidth>1200){
                setNumber(25)
                if(index<twoetwoevent.length-1){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else if(window.innerWidth>992){
                setNumber(33.3333)
                if(index<twoetwoevent.length){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else if(window.innerWidth>768){
                setNumber(50)
                if(index<twoetwoevent.length+1){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else{
                setNumber(100)
                if(index<twoetwoevent.length+2){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }
        }else{
            clearInterval(timer);
        }
    },2000)
    return (()=>{
        clearInterval(timer)
    })
})

    const changer={
        transform:`translateX(-${index*number}%)`
    }
// const myref=useRef();

// function scroller(){
//     setGallbox([...gallbox, ...twoetwoevent])
// }


    return (<>
             <div className="sectionbott mb-0 pb-5">
            <div className="container">
                <div className="row cetifier evenpm-2">
                    <h1>WORLD STROKE DAY 2022</h1>
                </div>
                <div className="row">
                    <div className="col-lg-12 conevent">
                    <h5>On the occasion of World Stroke Day, Bangalore Stroke Support Group and Karnataka Stroke Foundation is organising Karnataka Stroke Nursing Conclave 2022- A stroke program for the nurses on 22 nd Oct at BMC main auditorium, Bangalore Medical College.</h5>
                    </div>
                </div>
            <HomeInfo22v/>
            
            <div className="imagetagho"><h6>Image Gallery</h6></div>
                {/* <div ref={myref} onScroll={scroller} className="disgaller"> */}
                <div onClick={stop} className="rowgaleven">
                <div ref={myref} style={changer} className="disgaller disgallerydis">
                        {
                            twoetwoevent.map((elem)=>{
                                const {img}=elem;
                                return (<>
                                <LazyLoad className="col-xl-3 col-lg-4 col-md-6 col-12 pade">
                                    <div className="imgboxevent">
                                        <img className="imgboxev" src={img} alt="" />
                                    </div>
                                </LazyLoad>
                                </>)
                            })
                        }
                        {
                            twoetwoevent.map((elem, num)=>{
                                const {img}=elem;
                                if(num<3){
                                    return (<>
                                        <LazyLoad className="col-xl-3 col-lg-4 col-md-6 col-12 p-1">
                                    <div className="imgboxevent">
                                        <img className="imgboxev" src={img} alt="" />
                                        <img className="imgplus" src="photo/plus.png" alt="" />
                                    </div>
                                </LazyLoad>
                                        </>)
                                }
                            })
                        }
                    </div>
            <button onClick={right} className="leftbuttona"><img src="photo/left-arrow.png" alt="" /></button>
            <button onClick={left} className="rightbuttona"><img src="photo/right-arrow (2).png" alt="" /></button> 
                </div>
                <div className="eventbut">
                    <Link to="/event/twotwo" className="gallhomebu"><h5>Gallery</h5></Link>
                </div>
            </div>
        </div>
            </>)
}
export default memo(HomeGallery22);