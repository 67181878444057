export const Twothreeinfo = [
  {
    img: "../photo/im23001.jpg",
  },
  {
    img: "../photo/im23001.jpg",
  },
  {
    img: "../photo/im23002.jpg",
  },
  {
    img: "../photo/im23003.jpg",
  },
  {
    img: "../photo/im23004.jpg",
  },
  {
    img: "../photo/im23005.jpg",
  },
  {
    img: "../photo/im23006.jpg",
  },
  {
    img: "../photo/im23007.jpg",
  },
  {
    img: "../photo/im23008.jpg",
  },
  {
    img: "../photo/im23009.jpg",
  },
  {
    img: "../photo/im23010.jpg",
  },
  {
    img: "../photo/im23011.jpg",
  },
  {
    img: "../photo/im23012.jpg",
  },
  {
    img: "../photo/im23013.jpg",
  },
];
