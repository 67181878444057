import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Firstactivity(){
    return (<>
        <div className="sectionbott mb-0 pb-4 pt-5">
       <div className="container">
           <div className="row cetifier evenpm-2">
               <h3>Grand Launch for Bangalore Stroke Support Group</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>Understanding the seriousness of this situation, Bangalore Stroke Support Group (BPSS) is being set up with an aim to bring all the stakeholders i.e stroke specialists, physicians, rehabilitation specialists, healthcare professionals, hospital administrators, caregivers, stroke survivors, and public at large on a single platform to interact, share, discuss issues related to stroke. On this occasion on Thursday, 24th Sep, 2015, these key opinion experts will discussed and sensitized the media on the need for care among stroke victims and as a society we can adopt preventable measures.</h5>
               </div>
           </div>
           <div className="row">
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaaa.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaab.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaac.jpg" alt="" />
            </LazyLoad>
           </div>
       </div>
   </div>
    </>)
}
export default memo(Firstactivity)