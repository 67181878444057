import { memo } from "react"

function Contactform(){
    return (<>
    <section className="section">
        <div className="container">
            <div style={{textAlign:"center"}} className="row mb-4">
                <h1>Contact Us</h1>
            </div>
            <div className="row">
                <div className="col-xl-8">
                    <form className="former">
                        <div className="row">
                            <div className="col-md-6">
                                <input className="inputer mb-4" placeholder="Your Name"></input>
                            </div>
                            <div className="col-md-6">
                                <input className="inputer mb-4" placeholder="Email Address"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <input className="inputer mb-4" placeholder="Phone Number"></input>
                            </div>
                            <div className="col-md-6">
                                <input className="inputer mb-4" placeholder="Subject"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <textarea className="areaer mb-4" placeholder="Message"></textarea>
                            </div>
                        </div>
                        <div className="outersub">
                            <button className="submiter" type="submit"><h6>Sent Message</h6></button>
                        </div>
                    </form>
                </div>
                <div className="col-xl-4">
                <div style={{position:"relative",height:"100%",alignItems:"center"}} className="addinfob col-12 pt-3">
                    <h5>Address</h5>
                    <h6>Bangalore Parshvavayu Seva Sangha F-003, Nagarjuna Green Ridge Apartment, 19th Main Road, 27th Cross, 80 Feet Road 2nd Phase H.S.R Layout, Bangalore-560102</h6>

                    <h5>Phone</h5>
                    <h6>  Mr. Muralidharan K.V - +91 9901066260</h6>
                    
                    <h5>Email</h5>
                    <h6>muralidharankv@gmail.com</h6>

                    <h5 className="mb-0">Follow</h5>
                    <div className="followali">
                        <ul className="iconbox">
                            <li><a href=""><img src="photo/facebook (3).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/instagram (2).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/twitter (3).png" alt="" /></a></li>
                            <li><a href=""><img src="photo/google.png" alt="" /></a></li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Contactform)