import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/banner-1.jpg"
          alt="First slide"
        />
      </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/samllone.jpg"
          alt="First slide"
        />
        </div>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="big">
        <img
          className="d-block w-100"
          src="photo/banner-2.jpg"
          alt="Second slide"
        />
        </div>
      <div className="small">
        <img
          className="d-block w-100"
          src="photo/smalltwo.jpg"
          alt="First slide"
        />
        </div>

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;