import { memo, useState } from "react"
import Twooneinfo from "./Twooneinfo"
import LazyLoad from "react-lazy-load"


function Twoone(){
    const [number, setNumber]=useState(60)
    const [num3, setNum3]=useState(0)
    let setDis="flex"
    const menu=[{
                    src:"https://www.youtube.com/embed/sMCprxBBQdk",
                    info:"WSD 2021 Bangalore Stroke Support"
                },
                {
                    src:"https://www.youtube.com/embed/vsJTcD0jB9s?start=5",
                    info:"Short Video Of World Stroke Support Day 2021 By Bangalore Stroke Support"
                },
    ]
    let changer={
        display:`${setDis}`
    }
    function callme(){
        setDis="none"
        changer={
            display:`${setDis}`
        }
    }
    return (<>
                    
        <div className="sectionbott mb-0 pb-4 pt-5">
       <div className="container">
       <div className="vedioscrolla mb-5">
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2021 VIDEO</h3>
           </div>
                    <div className="row ">
                            {
                                menu.map((elem)=>{
                                    const {src,info}=elem;
                                    return (<>
                                    <div className="col-md-6 col-12 pade pt-3">
                                        <div className="vediooter">
                                            {/* <LazyLoad className="vedioinner"> */}
                                            <div className="imgve"></div>
                                            <iframe className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            {/* </LazyLoad> */}
                                        </div>
                                        <h5 className="mt-3">{info}</h5>
                                    </div>
                                    </>)
                                })
                            }
                        </div>
                    </div>
           <div className="row cetifier evenpm-2">
               <h3>WORLD STROKE DAY 2021</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>Bangalore Stroke Support Group organized World Stroke Day 2021 public event on Saturday 23rd, a walkathon followed by formal function at BMC Alumni auditorium, Bangalore. Chief guests, guest of honour, BSSG President & Secretary and ISA President and Secretary will share their message.</h5>
               </div>
           </div>
           <div className="row">
            {
                Twooneinfo.map((elem,num)=>{
                    const {img}=elem
                    if(Twooneinfo.length>num && number>num){
                        if(Twooneinfo.length-1==num){
                            callme()
                        }
                    {
                        return (<>
                        <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                            <img src={img} alt="" />
                        </LazyLoad>
                        </>)
                    }
                    }
                })
            }
           </div>
           <div style={changer} onClick={()=>{
            setNumber(number+60)
           }} className="loadmore pt-4">
            <button className="loadbut"><h6>Load More</h6></button>
           </div>
       </div>
   </div>
    </>)
    
}
export default memo(Twoone)