import { useEffect, useState } from "react";
import "./Inner.css"
import LazyLoad from "react-lazy-load";
function OfficeBear(){
    const [index,setIndex]=useState(0);
    const [bodyoneone, setBodyoneone]=useState(bodyone);
    const [bodytwotwo, setBodytwotwo]=useState(bodytwo);
    const [bodythreethree, setBodythreethree]=useState(bodythree);
    const [bodyfourfour, setBodyfourfour]=useState(bodyfour);
    const [righttrue, setRighttrue]=useState(true);
    useEffect(()=>{
        let settimer=setInterval(()=>{
            if(righttrue){
                if(index<3){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }else{
                clearInterval(settimer)
            }
        },3000)
        return ()=>{
            clearInterval(settimer);
        }
    })
    const transfer={
        transform:`translateX(-${index*16.666}%)`
       }
    function rightindex(){
        setRighttrue(false)
        if(index<3){
            setIndex(index+1)
        }else{
            setIndex(0)
        }
    }
    function leftindex(){
        setRighttrue(false)
        if(index>-1){
            setIndex(index-1);
        }else{
            setIndex(3);
        }
    }
    function sliderstop(){
        setRighttrue(false)
    }
    return (
        <>
        <section onClick={sliderstop} className="section">
            <div className="container">
                <div style={{paddingTop:"0px",marginTop:"0px"}} className="row cetifier">
                    <h1>CURRENT OFFICE BEARERS</h1>
                </div>
            </div>
            <div className="container">
                <div className="tittle">

                </div>
                <div className="headeroffbox">
                <div className="hiderofficeover">
                <div className="headeroffice" style={transfer}>
                    {bodytwotwo}
                    {bodythreethree}
                    {bodyfourfour}
                    {bodyoneone}
                    {bodytwotwo}
                    {bodythreethree}
                    
                </div>
                </div>
                <button onClick={rightindex} className="rightbutton"><img src="photo/right-arrow (2).png" alt="" /></button>
                <button onClick={leftindex} className="leftbutton"><img src="photo/left-arrow.png" alt="" /></button>
            </div>
            </div>
        </section>
        </>
    )
}
export default OfficeBear;

const bodyone=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="../photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>K.V.Muralidharan</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Treasurer</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodytwo=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                <img src="../photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Mr. N.K Mohan Ram</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Current President</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodythree=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                    <div className="backheder"></div>
                                    <LazyLoad>
                                        
                                <img src="../photo/amit.jpg" alt="" />
                                    </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Dr. Amit Kulkarni</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Hon. Secretary</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}

const bodyfour=()=>{
    return (
        <div className="widthheder">
                        <div className="widthheaderbody">
                            <div className="officebearphoto">
                                <div className="backheder"></div>
                                <LazyLoad>
                                    
                                <img src="../photo/officehead.png" alt="" />
                                </LazyLoad>
                            </div>
                            <div className="Bearname">
                                <h4>Mr.Sharad Kumar</h4>
                            </div>
                            <div className="officeinfo">
                                <div className="post">
                                    <h6>Joint Secretary</h6>
                                </div>
                                <ul className="officon">
                                    <li>
                                        <img src="../photo/facebook (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/twitter (4).png" alt="" />
                                    </li>
                                    <li>
                                        <img src="../photo/instagram (3).png" alt="" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    )
}