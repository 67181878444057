import { memo, useEffect, useRef, useState } from "react"
import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import LazyLoad from "react-lazy-load";

function HomeeventvedioTO(){
    const [number, setNumber]=useState()
    const [index,setIndex]=useState(2)
    const [cycle, setCycle]=useState(true)
    const [opaci, setOpaci]=useState("block")
    const [scroller, setScroller]=useState("none")
    const myref=useRef();
const menu=[{
                src:"https://www.youtube.com/embed/sMCprxBBQdk",
                info:"WSD 2021 Bangalore Stroke Support"
            },
            {
                src:"https://www.youtube.com/embed/vsJTcD0jB9s?start=5",
                info:"Short Video Of World Stroke Support Day 2021 By Bangalore Stroke Support"
            },
]

function stop(){
    setCycle(false)
    setOpaci("none")
    setScroller("scroll")
}

const [men,setmen]=useState(menu)
useEffect(()=>{

    window.addEventListener("resize",()=>{
        setIndex(0);
    })

    const timer=setInterval(()=>{
        if(cycle==true){  
          if(window.innerWidth<768){
                setNumber(100)
                if(index<menu.length-1){
                    setIndex(index+1);
                }else{
                    setIndex(0)
                }
            }
        }else{
            clearInterval(timer);
        }
    },2000)
    return (()=>{
        clearInterval(timer)
    })
})
const imgbox={
    display:`${opaci}`
}
const scroll={
    
    overflowX:`${scroller}`
}

    const changer={
        transform:`translateX(-${index*number}%)`
    }
    return (<>
    <div className="imagetagho"><h6>Video Gallery</h6></div>
                <div onClick={stop} style={scroll} className="vedioscrolla mb-5">
                <div ref={myref} style={changer} className="disgaller scrollconve">
                        {
                            men.map((elem)=>{
                                const {src,info}=elem;
                                return (<>
                                <div className="col-md-6 col-12 pade">
                                    <div className="vediooter">
                                        {/* <LazyLoad className="vedioinner"> */}
                                        <div style={imgbox} className="imgve"></div>
                                        <iframe onClick={stop} className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        {/* </LazyLoad> */}
                                    </div>
                                    <h5 className="mt-3">{info}</h5>
                                </div>
                                </>)
                            })
                        }
                        {
                            men.map((elem, num)=>{
                                const {src,info}=elem;
                                if(num<1 && index!=0 && window.innerWidth<768){
                                    return (<>
                                        <div className="col-md-6 col-12 pade">
                                            <div className="vediooter">
                                                {/* <LazyLoad className="vedioinner"> */}
                                                <div style={imgbox} className="imgve"></div>
                                                <iframe onClick={stop} className="imgboxevent vedieosize" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                {/* </LazyLoad> */}
                                            </div>
                                            <h5 className="mt-3">{info}</h5>
                                        </div>
                                        </>)
                                }
                            })
                        }
                    </div>
                </div>
    </>)
}
export default memo(HomeeventvedioTO)