import { memo } from "react"
import Aboutbanner from "./Aboutbanner"
import Hospitalinfo from "../Hospitalinfo"
import OfficeBear from "../OfficeBear"
import PastOffice from "../PastOffice"
import OfficeMember from "../OfficeMember"

function About(){
    return (
        <>
        <Aboutbanner/>
        <Hospitalinfo/>
        <OfficeBear/>
        <div style={{marginTop:"-70px"}}>
        <PastOffice/>
        </div>
        <OfficeMember/>
        </>
    )
}
export default memo(About)