import { memo } from "react"
import LazyLoad from "react-lazy-load"

function Secondactivity(){
    return (<>
        <div className="sectionbott mb-0 pb-4 pt-2">
       <div className="container">
           <div className="row cetifier evenpm-2">
               <h3>Fun based activity in improving balance for stroke survivors by Mr.Venkatesh</h3>
           </div>
           <div className="row">
               <div className="col-lg-12 conevent">
               <h5>The program on 20th March is as follows… 10am to 11 am —— Diet in Stroke by Mrs Emmany, Clinical Dietician 11 am to 12 noon — Fun based activity in improving balance for stroke survivors by Mr. Venkatesh, Neuro physical therapist.</h5>
               </div>
           </div>
           <div className="row">
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gaba.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gabb.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gabc.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gabd.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gabe.jpg" alt="" />
            </LazyLoad>
            <LazyLoad className="col-lg-4 col-md-6 mb-3 paddacti">
                <img src="photo/gabf.jpg" alt="" />
            </LazyLoad>
           </div>
       </div>
   </div>
    </>)
}
export default memo(Secondactivity)