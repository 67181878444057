import "./Inner.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
function FirstDivider(){
    return (<>
    <div className="section widthbanner">
        <div className="container">
            <div className="row lifegift">
                <h1>Life is a gift<br></br>your job is open it</h1>
            </div>
        </div>
    </div>
    </>)
}
export default FirstDivider;