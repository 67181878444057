import { memo, useState } from "react"
import { Link } from "react-router-dom"

function Officeselect(){
    const [first, setFirst]=useState("white");
    const [second, setSecond]=useState("black");
    const [three, setThree]=useState("#ff6d12");
    const [four, setFour]=useState("white");
    return (<>
    <section className="mt-4">
        <div className="container">
            <div className="row">
                <Link to="/office/present" onClick={()=>{
                    setFirst("white");
                    setSecond("black");
                    setThree("#ff6d12")
                    setFour("white")
                }} style={{textDecoration:"none"}} className="col-md-6">
                    <div style={{backgroundColor:`${three}`,color:`${four}`}} className="col-12 selectoffice">
                        <h5>Current Office Bearers</h5>
                    </div>
                </Link>
                <Link to="/office/past" onClick={()=>{
                    setThree("white")
                    setFour("black")
                    setFirst("#ff6d12")
                    setSecond("white")
                }} style={{textDecoration:"none"}} className="col-md-6">
                    <div style={{backgroundColor:`${first}`,color:`${second}`}} className="col-12 selectoffice">
                        <h5>Past Office Bearers</h5>
                    </div>
                </Link>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Officeselect)